import { default as version } from '../../package.json';

export const environment = {
  production: true,
  env: 'stage',
  clientVersion: version.version,
  sentry: {
    dsn: 'https://46788e1aab8c446aaa8eb426104e724d@sentry.io/1517372',
    environment: 'stage',
    debug: false,
    release: `frontend@${version.version}`
  },
  piwik: {
    containerId: '',
    containerUrl: ''
  }
};
