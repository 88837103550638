import { gql } from 'apollo-angular';

export const getRequiredShifts = gql`
  query requiredShifts($groupsIds: [Int]!, $dateFrom: String!, $dateTo: String!) {
    requiredShifts(groups: $groupsIds, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      code
      source
      parentType
      dateFrom
      dateTo
      timestamp
      alias
      skills
      endorsements
      licences
      isExcluded
      isLocked
    }
  }
`;

export const createRequiredShift = gql`
  mutation createRequiredShiftV2($group: Int!, $code: Int!, $timestamp: String!, $source: String!) {
    createRequiredShiftV2(group: $group, code: $code, timestamp: $timestamp, source: $source) {
      id
    }
  }
`;

export const deleteRequiredShifts = gql`
  mutation deleteRequiredShifts($ids: [Int]!) {
    deleteRequiredShifts(ids: $ids) {
      id
    }
  }
`;

export const removeRequiredScheduleShift = gql`
  mutation removeRequiredScheduleShift($id: Int!) {
    removeRequiredScheduleShift(id: $id) {
      id
    }
  }
`;

export const updateRequiredShift = gql`
  mutation updateRequiredShift($id: Int!, $isLocked: Boolean, $isExcluded: Boolean) {
    updateRequiredShift(id: $id, isLocked: $isLocked, isExcluded: $isExcluded) {
      id
    }
  }
`;

export const copyRequiredShifts = gql`
  mutation copyRequiredShifts($shiftsToCopy: [RequiredShiftCopyInput]!, $groupId: Int!) {
    copyRequiredShifts(shiftsToCopy: $shiftsToCopy, groupId: $groupId) {
      id
    }
  }
`;

export const replaceRequiredShifts = gql`
  mutation replaceRequiredShifts(
    $scheduleId: Int!
    $groups: [Int]!
    $dateRangeFrom: String!
    $dateRangeTo: String!
    $sourceCode: Int
    $targetCode: Int
    $shiftsLimit: Int
    $keepLockedShifts: Boolean
  ) {
    replaceRequiredShifts(
      scheduleId: $scheduleId
      groups: $groups
      dateRangeFrom: $dateRangeFrom
      dateRangeTo: $dateRangeTo
      sourceCode: $sourceCode
      targetCode: $targetCode
      shiftsLimit: $shiftsLimit
      keepLockedShifts: $keepLockedShifts
    ) {
      id
    }
  }
`;

export const requiredShiftsCreatedSubscription = gql`
  subscription requiredShiftsCreated($groupsIds: [Int]) {
    requiredShiftsCreated(groupsIds: $groupsIds) {
      shifts {
        id
        code
        source
        dateFrom
        dateTo
        group
        timestamp
        parentType
        alias
        skills
        endorsements
        licences
        type
      }
      changeTriggeredBy
      changeTriggeredById
    }
  }
`;

export const requiredShiftsUpdatedSubscription = gql`
  subscription requiredShiftsUpdated($groupsIds: [Int]) {
    requiredShiftsUpdated(groupsIds: $groupsIds) {
      shifts {
        id
        code
        source
        dateFrom
        dateTo
        group
        timestamp
        parentType
        alias
        skills
        endorsements
        licences
        type
        isLocked
        isExcluded
      }
      changeTriggeredBy
      changeTriggeredById
    }
  }
`;

export const requiredShiftsDeletedSubscription = gql`
  subscription requiredShiftsDeleted($groupsIds: [Int]) {
    requiredShiftsDeleted(groupsIds: $groupsIds) {
      shifts {
        id
        code
        source
        dateFrom
        dateTo
        group
        timestamp
        parentType
        alias
        type
      }
      changeTriggeredBy
      changeTriggeredById
    }
  }
`;
